import {BillerConfig} from './BillerConfig';
import {BillerConfigSchema, Gateways} from './schema';

export type BillerSlug =
  | 'city-of-exampleton'
  | 'city-of-kingston'
  | 'city-of-west-torrens'
  | 'wingecarribee-shire-council'
  | 'moreton-bay'
  | 'cessnock-city-council'
  | 'agl-energy'
  | 'energy-on-v1'
  | 'energy-on'
  | 'testing-shire'
  | 'testing-north-carolina'
  | 'nillumbik-shire-council'
  | 'berrigan-shire-council'
  | 'city-of-greater-dandenong'
  | 'campbelltown-city-council'
  | 'hornsby-shire-council'
  | 'mornington-peninsula-shire'
  | 'mount-isa-city-council'
  | 'city-of-parramatta'
  | 'douglas-shire-council'
  | 'wellington-shire-council'
  | 'moira-shire-council'
  | 'vic-park-council'
  | 'bega-valley-shire'
  | 'whitehorse-city-council'
  | 'hamilton-city-council'
  | 'yarra-ranges-council'
  | 'port-pirie-regional-council';

const NSW_LEGISLATED_DATES = [
  {year: 2024, month: 8, day: 31},
  {year: 2024, month: 11, day: 30},
  {year: 2025, month: 2, day: 28},
  {year: 2025, month: 5, day: 31},
];

const defaultExports: BillerConfigSchema['exports'] = [
  'all_plans',
  'daily_payments',
  'daily_failed_dd_payments',
  'payble_receipt_dd_v1',
  'payble_receipt_all_v1',
  'payble_receipt_card_v1',
];

export const RawBillerConfig: Record<BillerSlug, BillerConfigSchema> = {
  'city-of-exampleton': {
    id: '898ec588-41ea-4834-93c4-2fea2442eb89',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    canPayZeroBalanceAccount: true,
    hasYearlyRollover: true,
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: true,
      CATCH_UP: false,
      CONTACTLESS_PAYMENTS: true,
    },
    acceptedCardTypes: ['visa', 'masterCard'],
    paymentMethods: ['direct_debit', 'card'],
    paymentMethodForAccountType: {
      infringements: ['card'],
    },
    accountExternalIdLabel: 'Account number',
    consumerBaseURL: 'https://app.payble.com.au',
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    exports: defaultExports,
    smsSenderId: 'Exampleton',
    isLive: true,
    oneOffPayment: {
      type: 'amount-owing',
    },
    surcharges: [
      {
        type: 'card_brand_and_type',
        cardBrand: 'VISA',
        cardType: 'DEBIT',
        surchargeRate: 0.39,
      },
      {
        type: 'card_brand_and_type',
        cardBrand: 'MASTERCARD',
        cardType: 'DEBIT',
        surchargeRate: 0.36,
      },
      {
        type: 'card_brand_and_type',
        cardBrand: 'VISA',
        cardType: 'CREDIT',
        surchargeRate: 0.64,
      },
      {
        type: 'card_brand_and_type',
        cardBrand: 'MASTERCARD',
        cardType: 'CREDIT',
        surchargeRate: 0.77,
      },
      {
        type: 'card_brand',
        cardBrand: 'AMEX',
        surchargeRate: 0.77,
      },
      {type: 'card', fallback: true, surchargeRate: 0.36},
    ],
    setupCharges: {
      arrears: {},
      fines: {},
      legal_fees: {},
      milestone: {},
    },
    autopay: {
      automatic: ['rates', 'water'],
      annually: ['rates'],
    },
    catchUpPlans: {
      enabled: true,
      terms: [
        // GPTED TERMS FOR EXAMPLETON
        'Payment Schedule Example: The payment plan entails [X] installments, due on [specific dates or intervals, e.g., monthly, bi-weekly]. Each installment is to be paid in full by [specify payment method, e.g., bank transfer, credit card',
        'Late Payment Policy Example: Any payments not received within [X] days of the due date will incur a late fee of [specify amount or percentage]. Continued failure to make timely payments may result in [consequences, e.g., suspension of services, additional penalties',
        'Early Payment Option Example: Clients have the option to make early payments or pay off the entire remaining balance at any time without penalty. Early payments will be applied towards the outstanding balance and may result in reduced interest charges over the duration of the plan.',
      ],
      billingPeriods: {
        rates: ['mar', 'jun', 'sep', 'dec'],
        water: ['jun', 'dec'],
      },
      interest: {
        method: 'amortization',
        rate: 0.0475,
      },
    },
    requestedPlans: {
      enabled: true,
      availableAsAlternativePlan: true,
      terms: [
        // GPTED TERMS FOR EXAMPLETON
        'Payment Schedule Example: The payment plan entails [X] installments, due on [specific dates or intervals, e.g., monthly, bi-weekly]. Each installment is to be paid in full by [specify payment method, e.g., bank transfer, credit card',
        'Late Payment Policy Example: Any payments not received within [X] days of the due date will incur a late fee of [specify amount or percentage]. Continued failure to make timely payments may result in [consequences, e.g., suspension of services, additional penalties',
        'Early Payment Option Example: Clients have the option to make early payments or pay off the entire remaining balance at any time without penalty. Early payments will be applied towards the outstanding balance and may result in reduced interest charges over the duration of the plan.',
      ],
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      abn: '12 345 678 901',
      name: 'City of Exampleton',
      address: '123 Example Street, Exampleton, 3000 VIC',
      supportWebsite: 'https://www.city-of-exampleton.com.au/contact',
      email: 'info@city-of-exampleton.com.au',
      phone: '+61450907767',
      url: 'payble.city-of-exampleton.com.au',
      directDebitServiceAgreement:
        'https://payble.com.au/city-of-exampleton/ddrsa',
    },
    externalIdLabel: 'Account number',
    directDebitUserId: '123456',
    infringements: {
      enabled: true,
      terms: [
        'Council will allow payment to be made in instalments to make complete payment provided you:',
        '* Complete payment no more than 10 weeks after the issue date, AND',
        '* Do not miss more than 2 scheduled payments',
        'This agreement is a payment plan with Council. If the payment plan period ends without payment made in full by the last due date, any outstanding amount will be referred to Fines Victoria. Additional charges will apply.',
      ],
    },
    maxAttempts: {
      payer: {
        once: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        fixed: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        weekly: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        fortnightly: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        monthly: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        quarterly: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        automatic: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        annually: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        end_of_month: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
      },
    },
    notifications: {
      reEngageContacts: true,
    },
  },
  'city-of-kingston': {
    id: '395e6fa8-af39-485c-8dfc-2a1c98b14539',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    hasYearlyRollover: true,
    paymentMethods: ['direct_debit', 'card'],
    imports: {
      allowNonStaffProcessing: true,
    },
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: false,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: false,
      ONE_OFF_AMOUNT_LOCKED: true,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    accountExternalIdLabel: 'Assessment number',
    smsSenderId: 'KingstonCC',
    isLive: true,
    consumerBaseURL: 'https://payble.kingston.vic.gov.au',
    exports: defaultExports,
    oneOffPayment: {
      type: 'amount-owing',
    },
    setupCharges: {
      milestone: {},
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    payEveryX: {
      unavailablePeriod: {
        start: '2024-05-27',
        end: '2024-08-06',
      },
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'City of Kingston',
      email: 'info@kingston.vic.gov.au',
      abn: '80 640 377 247',
      address: '1230 Nepean Highway Cheltenham VIC 3192',
      phone: '1300 653 356',
      url: 'payble.kingston.vic.gov.au',
      openingTimes: '9:00am til 5:00pm',
      directDebitServiceAgreement:
        'https://payble.com.au/city-of-kingston/ddrsa',
    },
    externalIdLabel: 'Assessment number',
    directDebitUserId: '184532',
    notifications: {
      reEngageContacts: true,
    },
  },
  'city-of-west-torrens': {
    id: '5e80d6ab-ca20-47f0-9c68-05645895eebc',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney', //'Australia/Adelaide',
    currency: 'AUD',
    bpointBillerCode: {
      rates: '1943885',
    },
    featureConfig: {
      BALANCED: true,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: false,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    paymentMethods: ['direct_debit', 'card'],
    accountExternalIdLabel: 'Assessment number',
    consumerBaseURL: 'https://payble.westtorrens.sa.gov.au',
    exports: defaultExports,
    smsSenderId: 'CWT',
    isLive: true,
    oneOffPayment: {
      type: 'any-amount',
    },
    autopay: {
      automatic: ['rates'],
      annually: ['rates'],
    },
    setupCharges: {
      milestone: {},
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: true,
      availableAsAlternativePlan: false,
      terms: [
        'You have requested an extended payment arrangement, which will be review by the City of West Torrens Rates team.',
        'If your request is approved you will be notified within 10 business days.',
        'If your request is not approved, you will be contacted to discuss your circumstances in more detail.',
        'City of West Torrens is committed to helping our customers establish a payment plan to reduce any arrears in a reasonable timeframe.',
        'Approved payment plans will stop any penalty interest or charges being added to the assessment.',
        'Multiple defaults may result in cancellation of the plan.',
        'Refer to [councils policy](https://www.westtorrens.sa.gov.au/Council/Rates-and-charges) for more detail.',
      ],
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'City of West Torrens',
      abn: '16 346 877 634',
      address: '165 Sir Donald Bradman Drive, Hilton SA 5033',
      email: 'info@wtcc.sa.gov.au',
      phone: '+6184166333',
      supportWebsite: 'https://www.westtorrens.sa.gov.au/Council/Contact-us',
      url: 'payble.westtorrens.sa.gov.au',
      openingTimes: '8:30am to 5:00pm',
      directDebitServiceAgreement:
        'https://www.westtorrens.sa.gov.au/files/sharedassets/public/objective-digitalpublications/external-website/publications/direct-debit-request-terms-and-conditions.pdf',
    },
    externalIdLabel: 'Assessment number',
    directDebitUserId: '207720',
  },
  'city-of-greater-dandenong': {
    id: 'caf0d9b7-a536-4928-9218-93ee3b0894f3',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    paymentMethods: ['direct_debit', 'card'],
    paymentMethodForAccountType: {
      infringements: ['card'],
    },
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    calculatedInstalments: {
      periodStart: 'jul',
      instalmentDates: ['sep', 'nov', 'feb', 'may'],
    },
    bpointBillerCode: {
      rates: '2059129',
    },
    accountExternalIdLabel: 'Property number',
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    exports: defaultExports,
    consumerBaseURL: 'https://pay.greaterdandenong.vic.gov.au',
    smsSenderId: 'Payble',
    isLive: true,
    oneOffPayment: {
      type: 'max-account-balance',
    },
    setupCharges: {
      /** Dandenong only provides arrears and has no milestones in their data. */
      arrears: {},
    },
    autopay: {
      automatic: ['rates'],
      annually: ['rates'],
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: true,
      availableAsAlternativePlan: false,
      terms: [
        'You have requested an extended payment plan, which will be reviewed by the City of Greater Dandenong Council Rates team.',
        'If your request is approved you will be notified within 10 business days. You will be required to continue making payments by the due dates on your rates notice until advised otherwise by Council',
        'If your request is not approved, you will be contacted to discuss your circumstances in more detail.',
        'City of Greater Dandenong Council is committed to helping our customers establish a payment plan to reduce any arrears in a reasonable timeframe.',
        'Approved payment plans will stop any penalty interest being added to your property rates for the duration of the payment plan.',
        'Multiple defaults may result in cancellation of the plan and the resumption of interest accruing on any overdue amounts as well as possible legal action being undertaken to recover the debt.',
        'Payments will be debited from your nominated bank account or credit card and you will receive an SMS prior to each debit being processed',
        'Please note this plan only covers current overdue rates and the current financial years rates as at todays date, any other charges accrued throughout subsequent financial years will not be covered by this plan.',
        'Refer to [councils policy](https://www.greaterdandenong.vic.gov.au/rates) for more detail.',
      ],
    },
    nominatedAccountAgreement: {
      required: false,
    },
    payEveryX: {
      unavailablePeriod: {
        start: '2024-05-27',
        end: '2024-07-22',
      },
    },
    support: {
      name: 'City of Greater Dandenong',
      email: 'council@cgd.vic.gov.au',
      phone: '0385711000',
      address:
        'Civic Centre. 225 Lonsdale Street. Dandenong. Victoria Australia 3175',
      abn: '41 205 538 060',
      supportWebsite: 'https://www.greaterdandenong.vic.gov.au/contactus',
      url: 'pay.greaterdandenong.vic.gov.au',
      openingTimes: '8:30am to 5:00pm',
      directDebitServiceAgreement:
        'https://www.greaterdandenong.vic.gov.au/_flysystem/filerepo/A6461245',
    },
    externalIdLabel: 'Property number',
    directDebitUserId: '305273',
    infringements: {
      enabled: true,
      terms: [
        'Council will allow payment to be made in instalments to make complete payment provided you:',
        '* Complete payment no more than 10 weeks after the issue date, AND',
        '* Do not miss more than 2 scheduled payments',
        'This agreement is a payment plan with Council. If the payment plan period ends without payment made in full by the last due date, any outstanding amount will be referred to Fines Victoria. Additional charges will apply.',
      ],
    },
  },
  'wingecarribee-shire-council': {
    id: 'd0405f0a-fb7d-464d-bc87-23b070de82fa',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    canPayZeroBalanceAccount: true,
    featureConfig: {
      BALANCED: false,
      RECURRING: true,
      AUTO: true,
      PAY_EVERY_X: false,
      PAY_X_EVERY_Z: true,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    paymentMethods: ['direct_debit', 'card'],
    bpointBillerCode: {
      rates: '4085',
      water: '4077',
    },
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    exports: [
      'wingecarribee_all_plans',
      'daily_payments',
      'payble_receipt_dd_v1',
      'payble_receipt_card_v1',
      'payble_receipt_all_v1',
    ],
    accountExternalIdLabel: 'Property number',
    consumerBaseURL: 'https://payble.wsc.nsw.gov.au',
    smsSenderId: 'WSC',
    isLive: true,
    oneOffPayment: {
      type: 'any-amount',
    },
    setupCharges: {
      milestone: {},
    },
    autopay: {
      automatic: ['rates', 'water'],
      annually: ['rates'],
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Wingecarribee Shire Council',
      email: 'mail@wsc.nsw.gov.au',
      phone: '+6148680888',
      address: 'Civic Centre 68 Elizabeth Street Moss Vale NSW 2577',
      abn: '49 546 344 354',
      url: 'payble.wsc.nsw.gov.au',
      openingTimes: '8:30am til 4:30pm',
      directDebitServiceAgreement:
        'https://www.wsc.nsw.gov.au/Council/Connect-with-Council/Forms-and-Applications/Direct-Debit-Request-Service-Agreement',
    },
    externalIdLabel: 'Property number',
    noticeOnInterest: [
      '#### Notice on interest calculations:',
      'It is important to know that interest will still accrue on any amounts not paid in full by the due date.',
      'Please refer to your notice for details of when your payments are due.',
    ],
    directDebitUserId: '340330',
  },
  'moreton-bay': {
    id: '993cf07e-79de-41ec-ab86-d715f3ab6e51',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney', //'Australia/Brisbane',
    currency: 'AUD',
    canPayZeroBalanceAccount: true,
    featureConfig: {
      BALANCED: false,
      RECURRING: true,
      AUTO: true,
      PAY_EVERY_X: false,
      PAY_X_EVERY_Z: true,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    gateways: {
      card: 'transact',
      direct_debit: 'direct_link',
    },
    paymentMethods: ['direct_debit', 'card'],
    // Moreton bay has its own suite of exports
    consumerBaseURL: 'https://payble.moretonbay.qld.gov.au',
    accountExternalIdLabel: 'Property number',
    // These exports are duplicated across manual implementation in
    // services/biller and domain at time of writing. These exports are
    // exported to the "archive" directory with a "_v2" suffix. These will be
    // merged together in the future after they can be verified to be
    // feature-for-feature.
    exports: [
      't1_debtor_note',
      't1_payment_plan',
      't1_payment_receipt',
      't1_cancelled_plans',
    ],
    smsSenderId: 'Moreton Bay',
    isLive: true,
    oneOffPayment: {
      type: 'any-amount',
    },
    setupCharges: {
      milestone: {},
    },
    autopay: {
      automatic: ['rates'],
      annually: [],
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Moreton Bay',
      email: 'council@moretonbay.qld.gov.au',
      phone: '1300 522 192',
      abn: '92 967 232 136',
      address: '2 Hasking St, Caboolture, QLD 4510',
      supportWebsite: 'https://www.moretonbay.qld.gov.au/Contact/Request',
      url: 'payble.moretonbay.qld.gov.au',
      openingTimes: '8:30am to 5:00pm',
      directDebitServiceAgreement:
        'https://www.moretonbay.qld.gov.au/Services/Property-Ownership/Rates/Pay-Rates/Direct-Debit-Terms-and-Conditions?transfer=78d803e0-8678-4723-b215-eac5aadcee45',
    },
    externalIdLabel: 'Property number',
    privacyWarning:
      'City of Moreton Bay is collecting your personal information for the purpose of processing your payment request. Council will use your personal information to update Council’s customer information records and to contact you about other functions and services of Council.',
    noticeOnInterest: [
      '#### Important notice on interest calculations:',
      'If you are in arrears or are making a partial payment, it is important to know that interest is accrued daily on amounts outstanding after the due date.',
      'Please refer to your rates notice for more information.',
    ],
    directDebitUserId: '114311',
    remitter: 'MBRC - Payble',
  },
  'cessnock-city-council': {
    id: '630c0f8e-5168-4d5e-9c5b-cae6ad409c8e',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    featureConfig: {
      BALANCED: true,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: false,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: true,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 500,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 60,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 500,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 60,
      SMOOTH: false,
      CATCH_UP: false,
    },
    paymentMethods: ['direct_debit', 'card'],
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    accountExternalIdLabel: 'Assessment number',
    consumerBaseURL: 'https://payble.cessnock.nsw.gov.au',
    exports: [
      ...defaultExports,
      'civica_payment_receipt',
      'gateway_receipt_bpoint',
    ],
    smsSenderId: 'Cessnock',
    isLive: true,
    oneOffPayment: {
      type: 'any-amount',
    },
    setupCharges: {
      milestone: {},
      arrears: {},
    },
    autopay: {
      automatic: ['rates'],
      annually: ['rates'],
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Cessnock City Council',
      email: 'council@cessnock.nsw.gov.au',
      phone: '+6149934100',
      abn: '60 919 148 928',
      address: '62-78 Vincent St, Cessnock NSW 2325',
      url: 'payble.cessnock.nsw.gov.au',
      openingTimes: '9:00am til 5:00pm',
      directDebitServiceAgreement:
        'https://www.cessnock.nsw.gov.au/Council/Forms-and-documents/Forms/Direct-Debit-Request-Form-Rates',
    },
    externalIdLabel: 'Assessment number',
    privacyWarning:
      "Information you submit is collected for the purpose of managing the payment of your rates and is subject to Payble's [Terms and Privacy Policy](https://payble.com.au/privacy-terms), Council's Privacy Management Plan and Cessnock City Council’s [Privacy Statement.](https://www.cessnock.nsw.gov.au/Site-Footer/Sub-Footer-Links/Privacy-Statement)",
    directDebitUserId: '064347',
  },
  'nillumbik-shire-council': {
    id: 'a16e46a2-b106-4f55-bfd9-a08387cf4b45',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    accountVerificationCodeSource: {
      rates: 'post code of this property',
    },
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: false,
      ONE_OFF_AMOUNT_LOCKED: true,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    paymentMethods: ['direct_debit', 'card'],
    bpointBillerCode: {
      rates: '2069599',
    },
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    exports: ['pathway_cancelled_plans', 'pathway_plans', ...defaultExports],
    accountExternalIdLabel: 'Assessment number',
    consumerBaseURL: 'https://pay.nillumbik.vic.gov.au',
    smsSenderId: 'Payble',
    isLive: true,
    oneOffPayment: {
      type: 'amount-owing',
    },
    autopay: {
      automatic: ['rates'],
      annually: [],
    },
    setupCharges: {
      milestone: {},
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: true,
      availableAsAlternativePlan: false,
      terms: [
        'You have requested an extended payment arrangement, which will be review by the Nillumbik Shire Rates team.',
        'If your request is approved you will be notified within 10 business days.',
        'If your request is not approved, you will be contacted to discuss your circumstances in more detail.',
        'Nillumbik Shire is committed to helping our customers establish a payment plan to reduce any arrears in a reasonable timeframe.',
        'Approved payment plans will stop any penalty interest or charges being added to the assessment.',
        'Multiple defaults may result in cancellation of the plan.',
        'Refer to [councils policy](https://www.nillumbik.vic.gov.au/Residents/Rates-valuations-and-property/1-Pay-your-rates) for more detail.',
      ],
    },
    payEveryX: {
      unavailablePeriod: {
        start: '2024-05-27',
        end: '2024-08-10',
      },
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Nillumbik Shire Council',
      email: 'nillumbik@nillumbik.vic.gov.au',
      phone: '0394333111',
      abn: '64 487 894 794',
      address: '32 Civic Dr, Greensborough VIC 3088',
      supportWebsite:
        'https://www.nillumbik.vic.gov.au/Council/Contact-us/How-to-contact-us',
      url: 'https://pay.nillumbik.vic.gov.au/',
      openingTimes: '8:30am to 5:00pm',
      directDebitServiceAgreement:
        'https://www.nillumbik.vic.gov.au/files/assets/public/residents/rates-valuation-and-property/rates/nsc-direct-debit-request-service-agreement-december-2022.pdf',
    },
    externalIdLabel: 'Assessment number',
    directDebitUserId: '535983',
  },
  'berrigan-shire-council': {
    id: '0184ce11-aaa5-4eb6-858a-974d1a82b449',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    paymentMethods: ['card', 'direct_debit'],
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: true,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    gateways: {
      card: 'bpoint',
      direct_debit: 'direct_link',
    },
    autopayTargetDates: NSW_LEGISLATED_DATES,
    accountExternalIdLabel: 'Assessment number',
    consumerBaseURL: 'https://pay.berriganshire.nsw.gov.au',
    exports: [...defaultExports, 'gateway_receipt_bpoint'],
    smsSenderId: 'Payble',
    isLive: true,
    oneOffPayment: {
      type: 'next-instalment-arrears',
    },
    autopay: {
      automatic: ['rates', 'water'],
      annually: ['rates'],
    },
    setupCharges: {
      arrears: {},
      fines: {},
      legal_fees: {},
      milestone: {},
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    payEveryX: {
      unavailablePeriod: {
        start: '2024-05-27',
        end: '2024-07-17',
      },
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Berrigan Shire Council',
      abn: '53 900 833 102',
      address: '56 Chanter Street Berrigan, NSW 2712',
      email: 'mail@berriganshire.nsw.gov.au',
      phone: '+61358885100',
      url: 'pay.berriganshire.nsw.gov.au',
      openingTimes: '9:00am til 4:30pm',
      directDebitServiceAgreement:
        'https://app.payble.com.au/cms/biller/berrigan-shire-council/Berrigan-Shire-Council-DDR-SA.pdf',
    },
    externalIdLabel: 'Assessment number',
    directDebitUserId: '650523',
    remitter: 'BSC - Payble',
  },
  'campbelltown-city-council': {
    id: 'fa15e23f-526b-43cb-8257-3cf958544ea2',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney', //'Australia/Adelaide',
    currency: 'AUD',
    paymentMethods: ['card'],
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    bpointBillerCode: {
      rates: '2067064',
    },
    gateways: {
      card: 'bpoint',
      direct_debit: null,
    },
    consumerBaseURL: 'https://pay.campbelltown.sa.gov.au',
    accountExternalIdLabel: 'Assessment number',
    exports: [
      'legacy_created_plans',
      'legacy_cancelled_plans',
      'all_plans',
      'payble_receipt_dd_v1',
      'payble_receipt_card_v1',
      'payble_receipt_all_v1',
    ],
    smsSenderId: 'Payble',
    isLive: true,
    oneOffPayment: {
      type: 'any-amount',
    },
    autopay: {
      automatic: ['rates'],
      annually: [],
    },
    setupCharges: {
      milestone: {},
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    payEveryX: {
      unavailablePeriod: {
        start: '2024-05-27',
        end: '2024-07-17',
      },
      targetDate: {
        day: 23,
        month: 6,
      },
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Campbelltown City Council',
      email: 'mail@campbelltown.sa.gov.au',
      abn: '31 459 914 087',
      address: '172 Montacute Road ROSTREVOR SA 5074',
      phone: '(08) 8366 9222',
      url: 'pay.campbelltown.sa.gov.au',
      openingTimes: '8:45am to 5:00pm (Closed Public Holidays)',
      directDebitServiceAgreement:
        'https://www.campbelltown.sa.gov.au/__data/assets/pdf_file/0028/237385/Rates-Direct-Debit-Request-Form-Quarterly.pdf',
    },
    adminCreatedPlans: {
      flexibleFixed: {
        enabled: false,
      },
      flexible: {
        enabled: false,
      },
      autoPay: {
        enabled: false,
      },
    },
    externalIdLabel: 'Assessment number',
  },
  'mornington-peninsula-shire': {
    id: 'bd729ea6-583b-4e61-a606-78e48a8dc86a',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: true,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    gateways: {
      card: 'transact',
      direct_debit: 'direct_link',
    },
    paymentMethods: ['direct_debit', 'card'],
    acceptedCardTypes: ['masterCard', 'visa', 'americanExpress'],
    exports: [
      ...defaultExports,
      't1_payment_receipt',
      'mornpen_payment_receipt',
      'legacy_cancelled_plans',
    ],
    consumerBaseURL: 'https://rates.mornpen.vic.gov.au',
    accountExternalIdLabel: 'Property number',
    smsSenderId: 'Payble',
    isLive: true,
    oneOffPayment: {
      type: 'amount-owing',
    },
    autopay: {
      automatic: ['rates'],
      annually: [],
    },
    setupCharges: {
      milestone: {},
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    surcharges: [
      {
        type: 'card_brand_and_type',
        cardBrand: 'VISA',
        cardType: 'DEBIT',
        surchargeRate: 0.39,
      },
      {
        type: 'card_brand_and_type',
        cardBrand: 'MASTERCARD',
        cardType: 'DEBIT',
        surchargeRate: 0.36,
      },
      {
        type: 'card_brand_and_type',
        cardBrand: 'VISA',
        cardType: 'CREDIT',
        surchargeRate: 0.64,
      },
      {
        type: 'card_brand_and_type',
        cardBrand: 'MASTERCARD',
        cardType: 'CREDIT',
        surchargeRate: 0.77,
      },
      {
        type: 'card_brand',
        cardBrand: 'AMEX',
        surchargeRate: 0.77,
      },
      {type: 'card', fallback: true, surchargeRate: 0.36},
    ],
    maxAttempts: {
      payer: {
        once: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        fixed: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        weekly: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        fortnightly: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        monthly: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        quarterly: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        automatic: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        annually: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        end_of_month: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
      },
    },
    nominatedAccountAgreement: {
      required: true,
      allowAuthorityToOperate: true,
      terms: [
        'You are authorised to operate the nominated account',
        'If signing for a company or joint account you are authorised to sign on behalf of the company or other account holders, and',
        'You have understood and agreed to the terms and conditions set out in this Request and in your Direct Debit Request Service Agreement.',
      ],
    },
    support: {
      name: 'Mornington Peninsula Shire',
      email: 'revenuebanking@mornpen.vic.gov.au',
      abn: '53 159 890 143',
      address: '90 Besgrove Street Rosebud 3939',
      phone: '03 5950 1080',
      url: 'payble.com.au',
      directDebitServiceAgreement:
        'https://rates.mornpen.vic.gov.au/cms/biller/mornington-peninsula-shire/Mornington-Peninsula-Shire-DDR-SA.pdf',
      openingTimes: '8.30am - 4.30pm',
    },
    accountsExemptFromCollectionWhenArrearsOver: 1_000_00,
    externalIdLabel: 'Property number',
    directDebitUserId: '662816',
    remitter: 'MPS - Payble',
  },
  'city-of-parramatta': {
    id: '1c770f8d-5379-4a51-a1ec-c1b92be810be',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    paymentMethods: ['direct_debit', 'card'],
    featureConfig: {
      BALANCED: true,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    gateways: {
      card: 'transact',
      direct_debit: 'direct_link',
    },
    inboundAccountExternalIdNormalization: {
      character: '0',
      length: 8,
      type: 'pad_start',
    },
    accountExternalIdLabel: 'Assessment number',
    exports: defaultExports,
    consumerBaseURL: 'https://pay.cityofparramatta.nsw.gov.au',
    smsSenderId: 'Payble',
    isLive: true,
    oneOffPayment: {
      type: 'max-account-balance',
    },
    autopay: {
      automatic: ['rates'],
      annually: [],
    },
    setupCharges: {
      milestone: {},
    },
    payEveryX: {
      unavailablePeriod: {
        start: '2025-06-25',
        end: '2025-10-10',
      },
      targetDate: {
        day: 30,
        month: 6,
      },
      extendPlanTerms: [
        '**ARRANGEMENT WITH INTEREST**',
        'The intention of this payment arrangement is to have the account paid by 30th June of the current financial year.',
        'City of Parramatta is committed to helping our customers establish a payment plan to reduce any overdue amount in a reasonable timeframe and avoid any debt recovery or legal charges on the account.',
        '**Amounts not received by the due date are subject to daily simple interest charges in accordance with Section 566 of the Local Government Act 1993. The interest rate is shown on the front of your notice.**',
        'Any overdue amount will accrue interest changes, noting that an approved payment plan does not stop accrual of interest or charges to the assessment.',
        'Refer to your Rates notice for more information on interest charges. **Interest is charged at a rate of 10.5% daily.**',
        'Multiple defaults may result in cancellation of the plan. Any balance on the account not paid by 30th June of the current financial year will rollover as an overdue amount into the next financial year.',
      ],
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: true,
      availableAsAlternativePlan: false,
      terms: [
        'You have requested an extended payment arrangement, which will be reviewed by the City of Parramatta Rates Team.',
        'If your request is approved, you will be notified within 10 business days.',
        'If your request is not approved, you will be contacted to discuss your circumstances in more detail.',
        'City of Parramatta is committed to helping our customers establish a payment plan to reduce any arrears in a reasonable timeframe and avoid any debt recovery or legal charges on the account.',
        'Please note that any arrears on the account will continue to accrue interest charges on the assessment for the overdue amount only at the time. Approved payment plan does not stop any penalty interest or charges being added to the assessment.',
        'Refer to your Rates notice for information on interest charges. Multiple defaults may result in cancellation of the plan.',
      ],
    },
    nominatedAccountAgreement: {
      required: true,
      terms: [
        'Unfortunately due to banking requirements, joint account holders are required to complete a paper direct debit form with a wet signature.',
        'Alternatively to continue online, go back and change your payment option to ‘Credit/debit card’, or nominate an account that is not held a jointly.',
      ],
    },
    support: {
      openingTimes: '8.30am – 4.30pm',
      name: 'City Of Parramatta',
      email: 'council@cityofparramatta.nsw.gov.au',
      phone: '02 9806 5050',
      abn: '49 907 174 773',
      address: '126 Church St, Parramatta NSW 2150',
      url: 'pay.cityofparramatta.nsw.gov.au',
      directDebitServiceAgreement:
        'https://www.cityofparramatta.nsw.gov.au/sites/council/files/2019-01/Direct%20Debit%20Request%20Form.pdf',
    },
    externalIdLabel: 'Assessment number',
    directDebitUserId: '662377',
    remitter: 'COP - Payble',
  },
  'hornsby-shire-council': {
    id: '3dd14d50-6753-4388-ab37-90de126e528d',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    featureConfig: {
      BALANCED: true,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: false,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    paymentMethods: ['direct_debit', 'card'],
    acceptedCardTypes: ['masterCard', 'visa', 'americanExpress'],
    gateways: {
      card: 'quickstream',
      direct_debit: 'quickstream',
    },
    accountExternalIdLabel: 'Reference number',
    exports: [
      ...defaultExports,
      'gateway_receipt_quickstream',
      'gateway_failure_dd_quickstream',
    ],
    consumerBaseURL: 'https://pay.hornsby.nsw.gov.au',
    smsSenderId: 'Payble',
    isLive: true,
    oneOffPayment: {
      type: 'any-amount',
    },
    autopay: {
      automatic: ['rates'],
      annually: [],
    },
    setupCharges: {
      milestone: {},
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Hornsby Shire Council',
      email: 'accounts@hornsby.nsw.gov.au',
      abn: '20 706 996 972',
      address: 'PO BOX 37 HORNSBY NSW 2077',
      phone: '(02) 9847 6777',
      url: 'payble.com.au',
      openingTimes: 'Monday to Friday 8:30pm-5pm',
      directDebitServiceAgreement:
        'https://www.hornsby.nsw.gov.au/direct-debit-service-agreement',
    },
    externalIdLabel: 'Reference number',
    directDebitUserId: '661297',
    surcharges: [{type: 'card', surchargeRate: 0.5}],
  },
  'mount-isa-city-council': {
    id: 'd1959fc8-bb11-4858-9a9a-b34e576ddea4',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney', //'Australia/Brisbane',
    currency: 'AUD',
    paymentMethods: ['card', 'direct_debit'],
    featureConfig: {
      BALANCED: true,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: false,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    accountExternalIdLabel: 'Assessment number',
    gateways: {
      card: 'payway',
      direct_debit: 'payway',
    },
    exports: [...defaultExports, 'gateway_receipt_payway'],
    consumerBaseURL: 'https://pay.mountisa.qld.gov.au',
    smsSenderId: 'Payble',
    isLive: true,
    oneOffPayment: {
      type: 'max-account-balance',
    },
    autopay: {
      automatic: ['rates'],
      annually: [],
    },
    setupCharges: {
      arrears: {},
      fines: {
        labelTemplate: 'Fees/Other',
      },
      milestone: {},
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Mount Isa City Council',
      email: 'city@mountisa.qld.gov.au',
      phone: '07 4747 3200',
      address: '23 West St, Miles End QLD 4825',
      abn: '48 701 425 059',
      url: 'payble.com.au',
      directDebitServiceAgreement:
        'https://pay.mountisa.qld.gov.au/cms/biller/mount-isa-city-council/Mount-Isa-City-DDR-SA.pdf',
    },
    externalIdLabel: 'Assessment number',
    directDebitUserId: '314011',
  },
  'testing-shire': {
    id: '767c366b-a71a-4823-a0ee-0d5416833ec2',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'NZ',
    timezone: 'Australia/Sydney',
    currency: 'NZD',
    support: {
      email: 'support@payble.com.au',
      name: 'Testing Shire',
      phone: '666',
      url: 'https://localhost:6000',
    },
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: true,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
      CONTACTLESS_PAYMENTS: true,
    },
    paymentMethods: ['direct_debit', 'nz_direct_debit', 'card'],
    gateways: {
      card: 'mock',
      direct_debit: 'mock',
      nz_direct_debit: 'mock',
    },
    exports: defaultExports,
    accountExternalIdLabel: 'Account number',
    consumerBaseURL: 'https://app.payble.com.au',
    smsSenderId: 'Payble',
    isLive: false,
    oneOffPayment: {
      type: 'any-amount',
    },
    surcharges: [
      {
        type: 'card_brand_and_type',
        cardBrand: 'VISA',
        cardType: 'DEBIT',
        surchargeRate: 0.39,
      },
      {
        type: 'card_brand_and_type',
        cardBrand: 'MASTERCARD',
        cardType: 'DEBIT',
        surchargeRate: 0.36,
      },
      {
        type: 'card_brand_and_type',
        cardBrand: 'VISA',
        cardType: 'CREDIT',
        surchargeRate: 0.64,
      },
      {
        type: 'card_brand_and_type',
        cardBrand: 'MASTERCARD',
        cardType: 'CREDIT',
        surchargeRate: 0.77,
      },
      {
        type: 'card_brand',
        cardBrand: 'AMEX',
        surchargeRate: 0.77,
      },
      {type: 'card', fallback: true, surchargeRate: 0.36},
    ],
    setupCharges: {
      milestone: {},
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: true,
      availableAsAlternativePlan: true,
      terms: [
        // GPTED TERMS FOR EXAMPLETON
        'Payment Schedule Example: The payment plan entails [X] installments, due on [specific dates or intervals, e.g., monthly, bi-weekly]. Each installment is to be paid in full by [specify payment method, e.g., bank transfer, credit card',
        'Late Payment Policy Example: Any payments not received within [X] days of the due date will incur a late fee of [specify amount or percentage]. Continued failure to make timely payments may result in [consequences, e.g., suspension of services, additional penalties',
        'Early Payment Option Example: Clients have the option to make early payments or pay off the entire remaining balance at any time without penalty. Early payments will be applied towards the outstanding balance and may result in reduced interest charges over the duration of the plan.',
      ],
    },
    nominatedAccountAgreement: {
      required: false,
    },
    externalIdLabel: 'Account number',
  },
  'testing-north-carolina': {
    id: '32921ec4-4079-4272-bd04-98065d16c987',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'America/New_York',
    currency: 'AUD',
    support: {
      email: 'support@payble.com.au',
      name: 'Testing North Carolina',
      phone: '1337',
      url: 'https://localhost:1337',
    },
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: false,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: false,
      ONE_OFF_SCHEDULED: false,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    paymentMethods: ['direct_debit', 'card'],
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    exports: defaultExports,
    accountExternalIdLabel: 'Account number',
    consumerBaseURL: 'https://app.payble.com.au',
    smsSenderId: 'Payble',
    isLive: false,
    oneOffPayment: {
      type: 'amount-owing',
    },
    setupCharges: {
      milestone: {},
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    nominatedAccountAgreement: {
      required: false,
    },
    externalIdLabel: 'Account number',
  },
  'douglas-shire-council': {
    id: 'e45c86a8-62fa-4bde-ae4a-0ff6b2b3cda2',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney', //'Australia/Brisbane',
    currency: 'AUD',
    canPayZeroBalanceAccount: true,
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: false,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: true,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: true,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: true,
      CATCH_UP: true,
    },
    paymentMethods: ['direct_debit', 'card'],
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    accountExternalIdLabel: 'Assessment number',
    exports: [...defaultExports, 'gateway_receipt_bpoint'],
    consumerBaseURL: 'https://pay.douglas.qld.gov.au',
    smsSenderId: 'Payble',
    isLive: true,
    oneOffPayment: {
      type: 'any-amount',
    },
    autopay: {
      automatic: ['rates', 'water'],
      annually: [],
    },
    setupCharges: {
      arrears: {},
      fines: {
        labelTemplate: 'Fees/Other',
      },
      milestone: {},
    },
    bpointBillerCode: {
      rates: '2229466',
      water: '2229474',
    },
    catchUpPlans: {
      enabled: true,
      terms: [
        'The following payment options apply when:',
        '* you are unable to make full payment of your Rates and/or Water Notices by the due date and \n* are considering applying to Council to make a formal repayment arrangement.',
        "Under Council's Rates and Charges Debt Recovery Policy:",
        '[https://douglas.qld.gov.au/download/Debt-Recovery-General-Policy.pdf](https://douglas.qld.gov.au/download/Debt-Recovery-General-Policy.pdf), and in accordance with Chapter 4, Section 133 (1) of the *Local Government Regulation 2012*, (Interest on overdue rates or charges) - interest is payable on all overdue rates and charges owed to Council.',
        "All amounts owed under approved payment plans for outstanding rates and charges and/or water charges continue to accrue interest, compounding daily until the outstanding amount is paid in full, at the prescribed rate adopted in Council's Revenue Statement.",
        'This payment arrangement will not take effect until the first scheduled payment has been received by Council.',
        "If your payment arrangement is not maintained, your payment plan will be cancelled, and the debt may be forwarded to Council's external debt recovery agency without further notice to you, the ratepayer.",
      ],
      billingPeriods: {
        rates: ['jun', 'dec'],
        water: ['feb', 'jun', 'oct'],
      },
      interest: {
        method: 'amortization',
        rate: 0.1138,
      },
      showArrangementText: true,
    },
    requestedPlans: {
      enabled: false,
    },
    support: {
      name: 'Douglas Shire Council',
      email: 'enquiries@douglas.qld.gov.au',
      phone: '07 4099 9444',
      address: 'PO BOX 723, Mossman, QLD, 4873',
      abn: '71 241 237 800',
      url: 'pay.douglas.qld.gov.au',
      directDebitServiceAgreement:
        'https://douglas.qld.gov.au/wp-content/uploads/2015/11/Direct-Debit-Service-Agreement.pdf',
    },
    nominatedAccountAgreement: {
      required: false,
    },
    externalIdLabel: 'Assessment number',
    directDebitUserId: '405126',
  },
  'wellington-shire-council': {
    id: '212127ab-63ae-4d0b-bfdd-d97fcc218774',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: false,
      ONE_OFF_AMOUNT_LOCKED: true,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    accountExternalIdLabel: 'Property number',
    paymentMethods: ['direct_debit', 'card'],
    exports: [...defaultExports, 'gateway_receipt_bpoint'],
    consumerBaseURL: 'https://pay.wellington.vic.gov.au',
    smsSenderId: 'Payble',
    isLive: true,
    oneOffPayment: {
      type: 'amount-owing',
    },
    autopay: {
      automatic: ['rates', 'water'],
      annually: [],
    },
    setupCharges: {
      arrears: {},
      fines: {
        labelTemplate: 'Fees/Other',
      },
      milestone: {},
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: true,
      availableAsAlternativePlan: true,
      terms: [
        'You have requested an extended payment arrangement, which will be review by the Wellington Shire Rates team.',
        'If your request is approved you will be notified within 10 business days.',
        'If your request is not approved, you will be contacted to discuss your circumstances in more detail.',
        'Wellington Shire is committed to helping our customers establish a payment plan to reduce any arrears in a reasonable timeframe.',
        'Approved payment plans will stop any penalty interest or charges being added to the assessment.',
        'Multiple defaults may result in cancellation of the plan.',
        'Refer to [councils policy](https://www.wellington.vic.gov.au/council/manage-your-rates) for more detail.',
      ],
    },
    nominatedAccountAgreement: {
      required: false,
    },
    maxAttempts: {
      payer: {
        once: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        fixed: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        weekly: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        fortnightly: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        monthly: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        quarterly: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        automatic: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        annually: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
        end_of_month: {
          nz_direct_debit: 1,
          direct_debit: 1,
          card: 1,
        },
      },
    },
    support: {
      name: 'Wellington Shire Council',
      openingTimes: '9am – 4.30pm',
      email: 'enquiries@wellington.vic.gov.au',
      address: '18 Desailly Street, Sale Victoria 3850',
      abn: '18 420 243 468',
      phone: '1300 366 244',
      url: 'pay.wellington.vic.gov.au',
      directDebitServiceAgreement:
        'https://cdn.prod.website-files.com/6021ed7c89cc1c1c01fccf29/6671fdc4d6c77408841adad7_Wellington%20Direct%20Debit%20Request%20Service%20Agreement.pdf',
    },
    externalIdLabel: 'Assessment number',
    directDebitUserId: '066686',
    accountLookupAgreement: [
      'By clicking Lookup Account, I acknowledge, that I am the owner or authorized representative to act for this assessment.',
    ],
  },
  'moira-shire-council': {
    id: '1f6b27c6-d0f5-466c-bb22-bab4f28ed454',
    region: 'AU',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: true,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    gateways: {
      card: 'transact',
      direct_debit: 'direct_link',
    },
    accountExternalIdLabel: 'Assessment number',
    paymentMethods: ['direct_debit', 'card'],
    exports: defaultExports,
    consumerBaseURL: 'https://pay.moira.vic.gov.au',
    smsSenderId: 'Payble',
    isLive: true,
    oneOffPayment: {
      type: 'any-amount',
    },
    autopay: {
      automatic: ['rates'],
      annually: [],
    },
    setupCharges: {
      arrears: {},
      fines: {
        labelTemplate: 'Fees/Other',
      },
      milestone: {},
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Moira Shire Council',
      email: 'info@moira.vic.gov.au',
      address: ' 44 Station Street, Cobram',
      abn: '20 538 141 700',
      phone: '(03) 5871 9222',
      url: 'pay.moira.vic.gov.au',
      supportWebsite:
        'https://www.moira.vic.gov.au/Residents/Rates-and-charges-information/Pay-my-rates',
      directDebitServiceAgreement:
        'https://www.moira.vic.gov.au/files/sharedassets/public/v/2/forms/rates-forms/direct-debit-request-service-agreement-moira-shire-payble.pdf',
    },
    externalIdLabel: 'Assessment number',
    directDebitUserId: '660317',
    remitter: 'MSC - Payble',
    accountsExemptFromCollectionWhenArrearsOver: 2_500_00,
  },
  'bega-valley-shire': {
    id: 'ac5093bd-9c09-41cb-a321-f26db4507466',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    canPayZeroBalanceAccount: true,
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    accountVerificationCodeSource: {
      rates: 'valuation number of the property',
      water: 'reference number of the property',
    },
    featureConfig: {
      BALANCED: true,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: false,
      PAY_X_EVERY_Z: true,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 500,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: true,
      CATCH_UP: false,
    },
    bpointBillerCode: {
      rates: '1976752',
      water: '1976760',
    },
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    accountExternalIdLabel: 'Assessment number',
    accountsExemptFromCollectionWhenArrearsOver: 2_000_00,
    paymentMethods: ['direct_debit', 'card'],
    exports: [...defaultExports, 'gateway_receipt_bpoint'],
    consumerBaseURL: 'https://pay.begavalley.nsw.gov.au',
    smsSenderId: 'Payble',
    isLive: true,
    oneOffPayment: {
      type: 'max-account-balance',
    },
    autopay: {
      automatic: ['rates', 'water'],
      annually: [],
    },
    setupCharges: {
      arrears: {},
      milestone: {},
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Bega Valley Council',
      email: 'council@begavalley.nsw.gov.au',
      address: 'Zingel Place BEGA NSW 2550',
      abn: '26 987 935 332',
      phone: '(02) 6499 2222',
      url: 'pay.begavalley.nsw.gov.au',
      directDebitServiceAgreement:
        'https://begavalley.nsw.gov.au/services/direct-debit-application',
    },
    externalIdLabel: 'Assessment number',
    privacyWarning:
      'Bega Valley Shire Council will collect and store your information as required for the purpose of processing your payment request. In proceeding with use of the service you consent that Bega Valley Shire Council may use the information provided to update their customer information records and to contact you about other functions and services of Bega Valley Shire Council. For more information visit the [Bega Valley Shire Council website](https://begavalley.nsw.gov.au/council/your-privacy).',
    directDebitUserId: '089196',
  },
  'agl-energy': {
    id: '638224ae-9717-4160-b677-fa3610fc5e5a',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    canPayZeroBalanceAccount: true,
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: false,
      PAY_EVERY_X: false,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 0,
      ONE_OFF: false,
      ONE_OFF_SCHEDULED: false,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    gateways: {
      card: null,
      direct_debit: null,
    },
    paymentMethods: ['direct_debit', 'card'],
    smsSenderId: 'AGL Energy',
    consumerBaseURL: 'https://app.payble.com.au',
    accountExternalIdLabel: 'Account number',
    isLive: false,
    exports: [],
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'AGL Energy',
      email: 'complaints@agl.com.au',
      phone: '13 12 45',
      url: 'pay.agl.com.au',
      address: '200 George St, Sydney NSW 2000',
      abn: '57 074 821 720',
      openingTimes: '9:00am til 4:30pm',
      directDebitServiceAgreement:
        'https://www.agl.com.au/terms-conditions/direct-debit-residential',
    },
    externalIdLabel: 'Account number',
  },
  'energy-on-v1': {
    id: '18d1f70e-7200-432a-9e18-e71c38f918a9',
    region: 'AU',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    paymentMethods: ['direct_debit'],
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: false,
      PAY_EVERY_X: false,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: false,
      ONE_OFF_SCHEDULED: false,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    gateways: {
      card: null,
      direct_debit: null,
    },
    smsSenderId: 'Energy On',
    accountExternalIdLabel: 'Account number',
    consumerBaseURL: 'https://app.payble.com.au',
    isLive: true,
    exports: [],
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Energy On',
      email: 'energyservices@energyon.com.au',
      abn: '90 145 225 485',
      address: 'PO Box 2624, Mt Waverley, VIC 3149',
      phone: '1300 323 263',
      url: 'pay.energyon.com.au',
      openingTimes: '9:00am til 4:30pm',
      directDebitServiceAgreement: 'https://payble.com.au/energy-on-ddrsa',
    },
    externalIdLabel: 'Account number',
    directDebitUserId: '535636',
  },
  'energy-on': {
    id: '021f584d-65cf-4275-bbaf-001b9e84ab11',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    canPayZeroBalanceAccount: true,
    accountCategoryLabel: 'Utility type',
    paymentMethods: ['direct_debit', 'card'],

    surcharges: [{type: 'card', surchargeRate: 1.0}],
    acceptedCardTypes: ['visa', 'masterCard', 'americanExpress'],
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: false,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: false,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    bpointBillerCode: {
      electricity: '778100',
      water: '778100',
    },
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    smsSenderId: 'Energy On',
    accountExternalIdLabel: 'Customer number',
    consumerBaseURL: 'https://payble.energyon.com.au',
    isLive: true,
    exports: ['all_plans', 'daily_payments'],
    oneOffPayment: {
      type: 'amount-owing',
    },
    setupCharges: {
      milestone: {},
    },
    autopay: {
      automatic: ['water', 'electricity'],
      annually: [],
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Energy On',
      email: 'TheTeam@EnergyOn.com.au',
      abn: '90 145 225 485',
      address: 'PO Box 2624, Mt Waverley, VIC 3149',
      phone: '1300 323 263',
      url: 'pay.energyon.com.au',
      openingTimes: '9:00am til 4:30pm',
      directDebitServiceAgreement: 'https://payble.com.au/energy-on-ddrsa',
    },
    adminCreatedPlans: {
      flexibleFixed: {
        enabled: false,
      },
      flexible: {
        enabled: false,
      },
      autoPay: {
        enabled: true,
      },
    },
    externalIdLabel: 'Customer number',
    directDebitUserId: '535636',
  },
  'vic-park-council': {
    id: 'e75cdb58-4886-4f77-a9cb-7d0e2e7dc49f',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney', //'Australia/Perth',
    currency: 'AUD',
    paymentMethods: ['direct_debit', 'card'],
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: false,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: true,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: false,
      ONE_OFF_AMOUNT_LOCKED: true,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: true,
    },
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    accountExternalIdLabel: 'Assessment number',
    consumerBaseURL: 'https:/pay.vicpark.wa.gov.au',
    exports: [
      ...defaultExports,
      'gateway_receipt_bpoint',
      'legacy_cancelled_plans',
    ],
    smsSenderId: 'Payble',
    isLive: true,
    oneOffPayment: {
      type: 'amount-owing',
    },
    autopay: {
      automatic: ['rates'],
      annually: ['rates'],
    },
    setupCharges: {
      milestone: {},
      arrears: {},
      fines: {},
      legal_fees: {},
    },
    catchUpPlans: {
      enabled: true,
      billingPeriods: {
        rates: ['jun'],
      },
      interest: {
        method: 'amortization',
        rate: 0.055,
      },
      terms: [
        'The following payment options are for if you to setup a weekly, fortnightly or monthly payment schedule contribution to your rates amount.',
        "Under Council's Debt Collection Policy 303 and in accordance with Division 6, Subdivision 1, Section 6.51 of the Local Government Act 1995, interest is payable on all overdue rates and charges. All approved payment commitments for outstanding rates and charges will continue to accrue interest at the prescribed rate of 8% as adopted in Councils 2024-25 Budget for the relevant period, per annum compounding daily until the outstanding amount is paid in full.",
      ],
    },
    requestedPlans: {
      enabled: false,
    },
    surcharges: [{type: 'card', surchargeRate: 1.0}],
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Town of Victoria Park',
      abn: '77 284 859 739',
      address:
        'Administration building, 99 Shepperton Rd, Victoria Park WA 6100',
      email: 'admin@vicpark.wa.gov.au',
      phone: '(08) 9311 8111',
      url: 'https:/pay.vicpark.wa.gov.au',
      openingTimes: 'Opening times: 09:00 - 16:30',
      directDebitServiceAgreement:
        'https:/pay.vicpark.wa.gov.au/cms/biller/vic-park-council/Town-of-Victoria-Park-DDRSA.pdf',
    },
    externalIdLabel: 'Assessment number',
    directDebitUserId: '405609',
  },
  'whitehorse-city-council': {
    id: 'd16e1483-39b5-4b22-8e4f-56d2432103a6',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    paymentMethods: ['direct_debit', 'card'],
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: false,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    billerPortalFeatureConfigOverride: {
      AUTO: true,
    },
    accountExternalIdLabel: 'Assessment number',
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    exports: [...defaultExports, 'gateway_receipt_bpoint'],
    consumerBaseURL: 'https://pay.whitehorse.vic.gov.au',
    smsSenderId: 'Payble',
    isLive: true,
    oneOffPayment: {
      type: 'max-account-balance',
    },
    setupCharges: {
      milestone: {},
    },
    autopay: {
      automatic: ['rates'],
      annually: [],
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    surcharges: [
      {
        type: 'card_type',
        cardType: 'DEBIT',
        surchargeRate: 0,
      },
      {
        type: 'card_type',
        cardType: 'CREDIT',
        surchargeRate: 0.6,
      },
      {type: 'card', fallback: true, surchargeRate: 0},
    ],
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Whitehorse City Council',
      email: 'customer.service@whitehorse.vic.gov.au',
      phone: '03 9262 6333',
      address: '379-399 Whitehorse Road Nunawading VIC 3131',
      abn: '39 549 568 822',
      url: 'pay.whitehorse.vic.gov.au',
      openingTimes: 'Monday – Friday 8.30am – 5pm',
      directDebitServiceAgreement:
        'https://www.whitehorse.vic.gov.au/sites/whitehorse.vic.gov.au/files/assets/documents/Direct-Debit-Request-Service-Agreement.pdf',
    },
    externalIdLabel: 'Assessment number',
    directDebitUserId: '017523',
  },
  'hamilton-city-council': {
    id: '215d50c9-a708-4e1a-8259-4ba5e8c0a98a',
    smsProvider: 'sinch',
    emailProvider: 'smtp',
    region: 'NZ',
    timezone: 'Pacific/Auckland',
    currency: 'NZD',
    paymentMethods: ['card', 'nz_direct_debit'],
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 35,
      SMOOTH: false,
      CATCH_UP: false,
      CONTACTLESS_PAYMENTS: true,
    },
    accountExternalIdLabel: 'Rates number',
    gateways: {
      card: 'mastercard_gateway',
      direct_debit: null,
      nz_direct_debit: 'bnz',
    },
    exports: [...defaultExports, 'civica_plan_arrangements'],
    consumerBaseURL: 'https://pay.hamilton.govt.nz',
    smsSenderId: 'Payble',
    isLive: true,
    oneOffPayment: {
      type: 'any-amount-default-upcoming',
    },
    setupCharges: {
      milestone: {},
      arrears: {},
      legal_fees: {labelTemplate: 'Penalties'},
      fines: {labelTemplate: 'Penalties'},
    },
    autopay: {
      automatic: ['rates'],
      annually: [],
    },
    catchUpPlans: {
      enabled: false,
    },
    requestedPlans: {
      enabled: false,
    },
    nominatedAccountAgreement: {
      required: false,
    },
    paymentMethodAgreement: {
      smsNotificationConsent: true,
    },
    support: {
      name: 'Hamilton City Council',
      email: 'rates@hcc.govt.nz',
      phone: '07 838 6688',
      address: '260 Anglesea Street, Hamilton Central, 3204',
      supportWebsite: 'https://hamilton.govt.nz',
      url: '',
      openingTimes: '09:00 - 17:00',
      directDebitServiceAgreement:
        'https://storage.googleapis.com/hccproduction-web-assets/public/Uploads/Documents/Content-Documents/Terms-and-Conditions-Direct-Debit-20240923.pdf',
      directDebitServicePaperForm:
        'https://storage.googleapis.com/hccproduction-web-assets/public/Uploads/Documents/Content-Documents/Direct-Debit-Authority-form-September-2024.pdf',
    },
    payEveryX: {
      unavailablePeriod: {
        start: '2025-06-25',
        end: '2025-10-10',
      },
      targetDate: {
        day: 30,
        month: 6,
      },
    },

    surcharges: [{type: 'card', surchargeRate: 1.0}],
    noticeOnInterest: [
      '#### Important notice:',
      'If you choose to pay after the due date or are only making a partial payment, please be aware that penalties may be applied to any outstanding amounts after the due date. If you cannot pay by the due date, consider selecting the flexible payment method for more options.',
      'For more information about these penalties, please consult your rates notice.',
    ],
    externalIdLabel: 'Rates number',
    accountVerificationCodeSource: {
      rates: 'verification number',
    },
    remitter: 'HCC - Payble',
    directDebitUserId: '0203206',
    verifyAccountDetails: {
      default: [
        {
          text: 'Enter the verification number which you can find on your',
        },
        {
          text: 'rates notice',
          component: 'LinkImageModal',
          props: {
            src: '/cms/biller/hamilton-city-council/Verification-number.png',
          },
        },
      ],
    },
  },
  'yarra-ranges-council': {
    id: 'eae0f643-aaad-4b99-b8dc-10b06fc19278',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    paymentMethods: ['direct_debit', 'card'],
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    accountExternalIdLabel: 'Assessment number',
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    exports: [...defaultExports, 'gateway_receipt_bpoint'],
    consumerBaseURL: 'https://pay.yarraranges.vic.gov.au',
    smsSenderId: 'YarraRanges',
    isLive: false,
    oneOffPayment: {
      type: 'max-account-balance',
    },
    setupCharges: {
      milestone: {},
    },
    autopay: {
      automatic: ['rates'],
      annually: [],
    },
    catchUpPlans: {
      enabled: false,
    },
    payEveryX: {
      unavailablePeriod: {
        start: '2025-06-25',
        end: '2025-10-01',
      },
      targetDate: {
        day: 22,
        month: 6,
      },
    },
    requestedPlans: {
      enabled: true,
      availableAsAlternativePlan: true,
      terms: [
        'You have requested an extended payment arrangement, which will be review by the Yarra Ranges Rates team.',
        'If your request is approved you will be notified within 10 business days.',
        'If your request is not approved, you will be contacted to discuss your circumstances in more detail.',
        'Yarra Ranges is committed to helping our customers establish a payment plan to reduce any arrears in a reasonable timeframe.',
        'Approved payment plans will stop any penalty interest or charges being added to the assessment.',
        'Multiple defaults may result in cancellation of the plan.',
        'Refer to [councils policy](https://www.yarraranges.vic.gov.au/Council/Corporate-documents/Policies-strategies/Rate-Recovery-and-Financial-Hardship-Policy) for more detail.',
      ],
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Yarra Ranges Council',
      email: 'mail@yarraranges.vic.gov.au',
      phone: '1300 368 333',
      address: 'P.O. Box 105, Anderson Street Lilydale VIC 3140',
      abn: '21 973 226 012',
      url: 'pay.yarraranges.vic.gov.au',
      openingTimes: 'Monday – Friday 8.30am – 5pm',
      directDebitServiceAgreement:
        'https://www.yarraranges.vic.gov.au/Council/Rates/Pay-Your-Rates/Rates-direct-debit-agreement',
    },
    externalIdLabel: 'Assessment number',
    directDebitUserId: '66859',
  },
  'port-pirie-regional-council': {
    id: 'a01d8eaa-2dd2-47e5-a235-5a40790f6e77',
    smsProvider: 'twilio',
    emailProvider: 'postmark',
    region: 'AU',
    timezone: 'Australia/Sydney',
    currency: 'AUD',
    paymentMethods: ['direct_debit', 'card'],
    featureConfig: {
      BALANCED: false,
      RECURRING: false,
      AUTO: true,
      PAY_EVERY_X: true,
      PAY_X_EVERY_Z: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_LOCKED: false,
      FLEXIBLE_INSTALMENTS_AMOUNT_MIN: 1000,
      FLEXIBLE_INSTALMENTS_AMOUNT_MAX: 10000000,
      FLEXIBLE_INSTALMENTS_SCHEDULED_DAYS: 30,
      ONE_OFF: true,
      ONE_OFF_SCHEDULED: true,
      ONE_OFF_AMOUNT_LOCKED: false,
      ONE_OFF_AMOUNT_MIN: 1000,
      ONE_OFF_AMOUNT_MAX: 10000000,
      ONE_OFF_SCHEDULED_DAYS: 30,
      SMOOTH: false,
      CATCH_UP: false,
    },
    accountExternalIdLabel: 'Assessment number',
    gateways: {
      card: 'bpoint',
      direct_debit: 'bpoint',
    },
    exports: [...defaultExports, 'gateway_receipt_bpoint'],
    consumerBaseURL: 'https://pay.pirie.sa.gov.au',
    smsSenderId: 'PPRCouncil',
    isLive: false,
    oneOffPayment: {
      type: 'max-account-balance',
    },
    setupCharges: {
      milestone: {},
    },
    autopay: {
      automatic: ['rates'],
      annually: [],
    },
    catchUpPlans: {
      enabled: false,
    },
    payEveryX: {
      unavailablePeriod: {
        start: '2025-06-25',
        end: '2025-10-01',
      },
      targetDate: {
        day: 21,
        month: 6,
      },
    },
    requestedPlans: {
      enabled: true,
      availableAsAlternativePlan: false,
      terms: [
        'You have requested an extended payment arrangement, which will be review by the Port Pirie Rates team.',
        'If your request is approved you will be notified within 10 business days.',
        'If your request is not approved, you will be contacted to discuss your circumstances in more detail.',
        'Port Pirie is committed to helping our customers establish a payment plan to reduce any arrears in a reasonable timeframe.',
        'Approved payment plans will stop any penalty interest or charges being added to the assessment.',
        'Multiple defaults may result in cancellation of the plan.',
        'Refer to [councils policy](https://www.pirie.sa.gov.au/what-do-councils-do/financial-services/council-rates) for more detail.',
      ],
    },
    nominatedAccountAgreement: {
      required: false,
    },
    support: {
      name: 'Port Pirie Regional Council',
      email: 'council@pirie.sa.gov.au',
      phone: '08 8633 9777',
      address: '115 Ellen Street, PO Box 45, Port Pirie SA, Australia 5540',
      abn: '11 548 839 129',
      url: 'pay.pirie.sa.gov.au',
      openingTimes: 'Monday – Friday 9am – 5pm',
      directDebitServiceAgreement: '',
    },
    externalIdLabel: 'Assessment number',
    directDebitUserId: '123456',
  },
};

export const getBillerConfig = (billerSlug: string, stage?: string) => {
  const slug = billerSlug as BillerSlug;
  const config = RawBillerConfig[slug];
  if (!config) {
    throw new Error(`Biller by slug ${slug} not configured`);
  }
  return new BillerConfig(config, slug, stage);
};

export const getBillerConfigById = (billerId: string, stage?: string) => {
  const res = Object.entries(RawBillerConfig).find(
    ([__, config]) => config.id === billerId
  );
  if (!res) {
    throw new Error(`Biller by id ${billerId} not configured`);
  }
  const [slug, config] = res;
  return new BillerConfig(config, slug as BillerSlug, stage);
};

export const getBillerAccountTypeInfo = (
  billerSlug: string,
  accountType: string
) => {
  const billerConfig = getBillerConfig(billerSlug);
  const accountTypesInfo = billerConfig.getAccountTypesInfo();

  return accountTypesInfo.find(x => x.id === accountType);
};
export const getBillerConfigByGateways = (
  gateways: Gateways[],
  stage?: string
) => {
  return Object.entries(RawBillerConfig)
    .filter(([__, config]) => {
      return Object.values(config.gateways).some(gateway =>
        gateways.includes(gateway as Gateways)
      );
    })
    .map(
      ([slug, config]) => new BillerConfig(config, slug as BillerSlug, stage)
    );
};

export function resolveSlugFromHost(host: string): string | undefined {
  for (const [slug, config] of Object.entries(RawBillerConfig)) {
    const url = new URL(config.consumerBaseURL);
    if (url.host.includes('payble.com.au')) {
      // Do not match our top level domain since many test billers use it.
      continue;
    }
    if (url.host === host) {
      return slug;
    }
  }
  return undefined;
}

export * from './BillerConfig';
export {DEFAULT_ACCOUNT_TYPES_INFO} from './handlers/getAccountTypesinfo';
export type {AccountTypeInfo} from './handlers/getAccountTypesinfo';
export type {PaymentOption} from './handlers/getPaymentOptions';
export * from './schema';
