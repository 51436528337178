import React from 'react';
import {Text} from './Text';
import {useUuid} from '../hooks/useUuid';

export interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  className?: string;
  hidden?: boolean;
}

export const Uuid = React.forwardRef<HTMLInputElement, Props>(
  ({className, hidden = true, value, ...props}, ref) => {
    const uuid = useUuid(value);

    return (
      <>
        {!hidden && <Text className={className}>{uuid}</Text>}
        <input value={uuid} {...props} type="hidden" ref={ref} />
      </>
    );
  }
);

Uuid.displayName = 'Uuid';
