import {TooltipMessage} from 'components/organisms/TooltipMessage';
import {ArchiveBoxXMarkIcon} from '@heroicons/react/24/solid';

export const ArchiveStatusPill = () => {
  return (
    <TooltipMessage
      tooltipText="This contact has been archived."
      tooltipBgColorAndHue="gray-50"
      tooltipTextColorAndHue="gray-500"
      showTooltip={true}
    >
      <span className="bg-red-100 text-red-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded">
        <ArchiveBoxXMarkIcon
          aria-hidden="true"
          className="w-2.5 h-2.5 me-1.5"
        />
        Archived
      </span>
    </TooltipMessage>
  );
};
