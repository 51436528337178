import {useId} from 'react';
import {useFormContext, useFieldArray} from 'react-hook-form';

export const useRegisterFieldArray = (name: string) => {
  const form = useFormContext();

  const id = useId();
  const fieldId = `${name}-${id}`;
  const {fields, append, remove, insert, prepend, swap, update} = useFieldArray(
    {
      control: form.control,
      name,
    }
  );

  return {
    form,
    id: fieldId,
    fields,
    append,
    remove,
    insert,
    prepend,
    swap,
    update,
  };
};
