import React from 'react';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {useAPIMutation} from 'lib/api';
import {useToast} from 'payble-ui';
import {CancelModal} from '../components/CancelModal';

type ArchiveContactFormProps = {
  disclosure: ReturnType<typeof useDisclosure>;
  contactId: string;
  refetch: () => void;
};

export const ArchiveContact: React.FC<ArchiveContactFormProps> = ({
  disclosure: {isOpen, onClose},
  contactId,
  refetch,
}) => {
  const {toast} = useToast();
  const {mutateAsync: archiveContact} = useAPIMutation('archiveContact', {
    query: {
      onSuccess: () => {
        toast({
          title: 'Successful',
          description: 'Contact has been archived',
          variant: 'success',
        });
        onClose();
        refetch();
      },
      onError: e => {
        toast({
          title: 'Failed to archive contact',
          description: e.message,
          variant: 'destructive',
        });
      },
    },
  });

  return (
    <CancelModal
      headline="Archive Contact"
      description="Archiving will cancel all active plans for this contact
                      and cease all communications. The contact will no longer
                      be able to access Payble once archived. Please select a reason from the options below:"
      isOpen={isOpen}
      onClose={onClose}
      onReject={async reason => {
        await archiveContact({
          contactId,
          archivedBy: 'Biller archived contact',
          reason,
        });
      }}
      reasons={['Customer requested', 'Other']}
    />
  );
};
