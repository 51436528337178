import {FormConfigField, FormConfig} from 'payble-shared';
import {FormFieldFactory} from './FormFieldFactory';

export interface Props {
  field: FormConfigField;
  context?: FormConfig['context'];
  className?: string;
}

export const FormBuilderField = ({field, className, context}: Props) => {
  const formFieldFactory = new FormFieldFactory(context);
  return (
    <div className={`flex-1 ${className}`}>
      {formFieldFactory.createField(field)}
    </div>
  );
};
