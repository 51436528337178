import * as React from 'react';
import {Icon, IconProps} from './Icon';
import {Button, ButtonProps} from './Button';
import {VariantProps, cn, cva} from '../lib/utils';

const iconButtonVariants = cva('rounded-full', {
  variants: {},
});

export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof iconButtonVariants> {
  icon: IconProps['name'];
  variant?: ButtonProps['variant'];
  as?: 'button' | 'span' | 'div';
}

const IconButton = ({
  className,
  icon,
  variant = 'default',
  as = 'button',
  ...props
}: IconButtonProps) => {
  return (
    <Button
      as={as}
      variant={variant}
      size="icon"
      {...props}
      className={cn(iconButtonVariants({className}))}
    >
      <Icon name={icon} size={20} />
    </Button>
  );
};
IconButton.displayName = 'IconButton';

export {IconButton};
