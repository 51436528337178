import {FC, ReactNode} from 'react';
import {InputProps} from './Input';
import {FormField} from './FormField';
import {useRegisterField} from '../hooks/useRegisterField';
import {Uuid} from './Uuid';

export interface Props extends InputProps {
  label?: ReactNode;
  name: string;
}

export const FormUuid: FC<Props> = ({label, name, ...props}) => {
  const {id, field, form} = useRegisterField(name);

  return (
    <FormField id={id} name={name} label={label}>
      <Uuid id={id} {...props} {...field} value={form.getValues(name)} />
    </FormField>
  );
};
