import {FC, ReactNode} from 'react';
import {useRegisterFieldArray} from '../hooks/useRegisterFieldArray';
import {FormField} from './FormField';
import {FormBuilderField} from './FormBuilderField';
import {FormConfig, FormConfigField} from 'payble-shared';
import {IconButton} from './IconButton';
import {Button} from './Button';

type FormFieldListProps = {
  description?: ReactNode;
  label?: ReactNode;
  fields: FormConfigField[];
  addItemLabel?: string;
  context?: FormConfig['context'];
  name: string;
};

export const FormFieldList: FC<FormFieldListProps> = ({
  name,
  label,
  addItemLabel = 'Add item',
  description,
  fields,
  context,
}) => {
  const {id, fields: formFields, append, remove} = useRegisterFieldArray(name);

  return (
    <FormField id={id} name={name} label={label} description={description}>
      <ul className="flex flex-col gap-3">
        {formFields.map((item, index) => (
          <li key={item.id} className="flex items-center gap-3">
            {fields.map(field => (
              <FormBuilderField
                key={`${item.id}${field.name}`}
                field={{
                  ...field,
                  name: `${name}.${index}.${field.name}`,
                  label: index === 0 ? field.label : '',
                }}
                context={context}
                className={'hidden' in field && field.hidden ? 'hidden' : ''}
              />
            ))}
            <IconButton
              onClick={() => remove(index)}
              icon="trash"
              variant="ghost"
              className="shrink-0"
              style={{marginTop: index === 0 ? '35px' : 0}}
            />
          </li>
        ))}
      </ul>
      <Button
        onClick={() => append({})}
        variant="outline"
        className="w-auto"
        size="sm"
      >
        {addItemLabel}
      </Button>
    </FormField>
  );
};
