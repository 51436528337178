import {z} from 'zod';
import {contactResponse, contactSourceSchema} from '../schemas';
import {defineHTTPEndpoint} from '../utils';

export const createContact = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'POST /createContact',
  requestSchema: z.object({
    givenName: z.string(),
    familyName: z.string(),
    email: z.union([z.literal(''), z.string().email()]),
    mobileNumber: z.string().min(1).trim(),
    sendIntroduction: z.union([z.null(), z.boolean()]),
    source: contactSourceSchema.optional(),
  }),
  responseSchema: z.union([z.null(), contactResponse]),
});

export const updateContact = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'PUT /updateContact',
  requestSchema: z.object({
    givenName: z.string(),
    familyName: z.string(),
    email: z.union([z.literal(''), z.string().email()]),
    source: contactSourceSchema.optional(),
    contactId: z.string(),
  }),
  responseSchema: z.union([z.null(), contactResponse]),
});

export const archiveContact = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'PUT /archiveContact',
  requestSchema: z.object({
    contactId: z.string(),
    archivedBy: z.string(),
    reason: z.string(),
  }),
  responseSchema: null,
});
